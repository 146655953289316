/*
 * src/assets/scss/mixin.scss
 */
@mixin full_width_section{
  margin-left:-$MAIN_SIDE_PADDING; margin-right:-$MAIN_SIDE_PADDING;
}

@mixin object_fit{
  display:flex; align-items:center; justify-content:center; overflow:hidden;
  
  > img{
    object-fit:cover; min-width:100%; min-height:100%; max-width:100%; max-height:100%;
  }
}