
        @import "@/assets/scss/_var.scss";
        @import "@/assets/scss/_mixin.scss";
        @import "@/assets/scss/_function.scss";
        

/**/
.viewport_info{
  position:fixed; z-index:999999; top:0px; left:50%; transform:translateX(-50%);
  background:rgba(0, 0, 0, 0.7);
  color:#00ffff; font-size:11px; white-space:nowrap;
  cursor:pointer; padding:3px;
  -webkit-touch-callout:none; -webkit-user-select:none; -khtml-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none;
}

.viewport_info:before{ margin-right:5px; display:inline-block; }

/*.viewport_info:before{ content:'CSS : DESKTOP /'; }*/
/* [ @~lt ] 1439이하 - 랩탑 and 테블릿 가로 이하▼ 화면*/
@media screen and (max-width:1439px){
  /*.viewport_info:before{ content:'CSS : LAPTOP /'; }*/
}
