/*
 * src/assets/scss/ui.scss
 */
.splash_ui{
  z-index:0; position:absolute; top:0px; left:0px;
  width:100%; height:100%;
  display:flex; align-items:center; justify-content:center;
  font-size:0;
}

.splash_ui_2{
  z-index:200; position:fixed; top:0px; left:0px; width:100%; height:100%;
  
  > div{
    transition:1.2s;
    z-index:5; position:absolute; top:0px; left:0px; width:100%; height:100%;
  }
  
  .white{
    background-color:white;
    transform-origin:0 0;
    
    animation-name:kf_splash_white; /* keyframename|none|initial|inherit; */
    animation-duration:1.2s; /* time|initial|inherit; */
    animation-iteration-count:1; /* number|infinite|initial|inherit; */
    animation-direction:normal; /* normal|reverse|alternate|alternate-reverse|initial|inherit; */
    animation-fill-mode:forwards; /* none|forwards|backwards|both|initial|inherit; */
    animation-play-state:running; /* paused|running|initial|inherit; */
    animation-timing-function:cubic-bezier(.645, .045, .355, 1); /* linear|ease|ease-in|ease-out|cubic-bezier(n,n,n,n)|initial|inherit;*/
    
  }
  
  .black{
    display:flex; align-items:center; justify-content:center;
    background-color:black;
    
    animation-name:kf_splash_black; /* keyframename|none|initial|inherit; */
    animation-duration:1.2s; /* time|initial|inherit; */
    animation-delay:0.8s; /* time|initial|inherit; */
    animation-iteration-count:1; /* number|infinite|initial|inherit; */
    animation-direction:normal; /* normal|reverse|alternate|alternate-reverse|initial|inherit; */
    animation-fill-mode:forwards; /* none|forwards|backwards|both|initial|inherit; */
    animation-play-state:running; /* paused|running|initial|inherit; */
    animation-timing-function:cubic-bezier(.645, .045, .355, 1); /* linear|ease|ease-in|ease-out|cubic-bezier(n,n,n,n)|initial|inherit;*/
    
  }
  
}

@keyframes kf_splash_white{
  from{
    transform:translateX(0);
  }
  to{
    transform:translateX(-100%);
  }
}

@keyframes kf_splash_black{
  from{
    clip-path:inset(0 0 0 0);
  }
  to{
    clip-path:inset(0 100% 0 0);
  }
}

.titui_h1{
  margin-bottom:40px;
  font-family: 'Poppins', sans-serif; font-weight:700; font-size:20px; line-height:24px;
}

.titui_h1.line{
  /*margin-top:70px;*/ /*margin-right:-$MAIN_SIDE_PADDING;*/ /*padding-top:50px;*/
}

.titui_h1.line:before{
/*  position:absolute; top:0; left:0; content:""; clear:both;
  width:100%; height:3px;
  background-color:$N_COLOR_8;*/
}

.titui_h1.main{
  font-weight:700; font-size:34px; line-height:1em;
  
  > .dot{
    display:inline-block; margin-left:3px;
    width:5px; height:5px;
    background-color:$COLOR_0; /*vertical-align:bottom;*/
  }
}

.titui_h1.main.enm{
  @include full_width_section;
  margin-bottom:0;
  padding-left:$MAIN_SIDE_PADDING; padding-right:$MAIN_SIDE_PADDING;
  padding-top:70px; padding-bottom:32px;
  background-color:$N_COLOR_2;
}

.titui_h2{
  margin-top:60px;
  ont-family:"Montserrat"; font-weight:700; font-size:28px; line-height:36px;
}

h3.ksm{ background-color:cornflowerblue; color:white; margin-bottom:5px; padding:5px }

.color_0{ color:$COLOR_0; }

.n_color_8{ color:$N_COLOR_8; }

#layer_pop_container{
  z-index:1000; position:fixed; top:-200%; left:-200%;
  width:100%; height:100%;
}

.layer_popup{
  top:-1000%; left:-1000%;
  z-index:1000; position:fixed; width:100%; height:100%;
  display:flex; align-items:center; justify-content:center;
  
  > .bg{
    z-index:0; position:absolute; top:0px; left:0px; width:100%; height:100%;
    background-color:rgba(0, 0, 0, 0.7);
  }
  
  > .popup{
    z-index:5; position:relative;
    width:calc(100% - 28px * 2);
    background-color:#1d1d1d;
    
    > .head{
      font-size:20px; color:white;
      text-align:center;
    }
    
    > .body{
    }
    
    > .foot{
    }
  }
}

.layer_popup.on{
  top:0px; left:0px;
}

.layer_popup.on{
  > .popup{
    opacity:0;
    animation:0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.0s 1 normal forwards running fadein_scale;
  }
}

@keyframes fadein_scale{
  from{
    opacity:0;
    transform:scale(0.7);
  }
  to{
    opacity:1;
    transform:scale(1);
  }
}

/* full_layer_popup [2022-07-05(005)] */
.full_layer_popup{
  transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
  z-index:1000; position:fixed; top:0; right:-200%;
  width:100%; height:100%;
  
  > .bg{
    z-index:0; position:absolute; top:0px; left:0px; width:100%; height:100%;
  }
  
  > .popup{
    width:100%; height:100%;
    
    > .head{
      z-index:10; position:absolute; top:0px; right:0px;
      width:100%; height:$HEADER_HEI;
      background-color:rgba(0, 0, 0, 0.94);
      
      > .close_btn{
        width:48px; height:48px;
        z-index:0; position:absolute; bottom:6px; right:16px;
      }
      
      > .close_btn:after{
        display:inline-block; content:"";
        position:absolute; z-index:3;
        background-image:url(@/assets/svg_icon/icon_close.svg); background-position:center; background-repeat:no-repeat;
        width:100%; height:100%; top:0px; left:0px;
      }
    }
    
    > .body{
      z-index:20; position:absolute; top:$HEADER_HEI; left:0px;
      background-color:rgba(0, 0, 0, 0.94);
      padding-bottom:$MAIN_BOTTOM_PADDING;
      width:100%; height:calc(100% - #{$HEADER_HEI}); overflow:auto;
    }
  }
  
}

.full_layer_popup.on{
  right:0px;
}

/* // full_layer_popup [2022-07-05(005)] */

/* tmb_list_ui [2022-07-02] */
.tmb_list_ui{
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  gap:20px;
  
  > .item{
    aspect-ratio:600 / 600;
    font-size:0; overflow:hidden;
    
    .pic{
      height:100%;
      display:flex; align-items:center; justify-content:center;
      
      > img{
        min-width:100%; min-height:100%;
        object-fit:cover;
        border:none; outline:none;
      }
    }
  }
  
  > .item:before{
    position:absolute; width:100%; height:100%;
    display:block; content:""; clear:both;
    background-color:$N_COLOR_2;
  }
}

.tmb_list_ui.news{
  > .item{
    aspect-ratio:168 / 138;
    
    > .new{
      display:none;
    }
  }
  
  > .item:first-of-type{
    > .new{
      position:absolute; top:0; left:0;
      display:inline-block; padding:1px 5px;
      font-family: 'Poppins', sans-serif; font-weight:700; font-size:12px; line-height:15px; text-align:center;
      color:$N_COLOR_0; background-color:$COLOR_0;
    }
  }
}

.tmb_list_ui.partner{
  
  > .item{
    aspect-ratio:168 / 50;
  }
  
  > .item:before{
    display:none;
  }
}

/* 600px 이상 화면*/
@media screen and (min-width:600px){
  .tmb_list_ui{
    grid-template-columns: repeat(3, 1fr);
  }
}

/* // tmb_list_ui [2022-07-02] */

/* qna_list_ui [2022-07-03] */
.qna_list_ui{
  margin-right:-$MAIN_SIDE_PADDING;
  
  > label{
    display:block; width:100%;
    
    > .chk{
      z-index:0; position:absolute; top:0px; left:0px;
      pointer-events:none; opacity:0;
    }
    
    > .Q{
      border-top:2px solid $N_COLOR_4;
      padding-top:30px; padding-bottom:28px; padding-left:27px; padding-right:74px;
      
      > .qstn_txt{
        display:-webkit-box;
        overflow:hidden; word-break:break-all;
        font-weight:600; font-size:18px; line-height:28px; letter-spacing:-0.5px;
        -webkit-box-orient:vertical; -webkit-line-clamp:2;
      }
    }
    
    > .Q:before{
      position:absolute; left:0;
      display:inline-block; content:"Q";
      font-family: 'Poppins', sans-serif; font-style:normal; font-weight:500; font-size:18px; line-height:28px; letter-spacing:-0.5px;
    }
    
    > .Q:after{
      transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
      display:inline-block; content:"";
      position:absolute; z-index:3;
      background-image:url(@/assets/svg_icon/qna_down_arrow.svg); background-position:center; background-repeat:no-repeat;
      width:16px; height:11px; top:50%; transform:translateY(-50%) rotate(0deg); right:28px;
    }
    
    .A{
      transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
      display:block;
      padding-left:28px; padding-right:28px;
      background-color:$N_COLOR_2; border-bottom:2px solid $N_COLOR_4;
      font-weight:500; font-size:14px; line-height:24px; letter-spacing:-0.5px;color:$N_COLOR_7;
      max-height:0; overflow:hidden; padding-top:0; padding-bottom:0x;
    }
    
    .chk:checked{
      ~ .Q{
        border-bottom-width:0;
        
        > .qstn_txt{
          display:block;
        }
      }
      
      ~ .Q:before{
        color:$COLOR_0;
      }
      
      ~ .Q:after{
        transform:translateY(-50%) rotate(180deg);
      }
      
      ~ .A{
        max-height:50vh;
        padding-top:30px; padding-bottom:30px; border-bottom-width:3px; border-bottom-color:$N_COLOR_8;
      }
    }
    
  }
  
  > label + label{
    > .Q{
      border-top:none;
    }
    
  }
}

/* // qna_list_ui [2022-07-03] */

/* CustomAlert [2022-07-06] */
.CustomAlert{
  z-index:1000; position:fixed; top:0; left:-200%;
  width:100%; height:100%;
  display:flex; align-items:center; justify-content:center;
  
  > .bg{
    z-index:0; position:absolute; top:0px; left:0px; width:100%; height:100%;
    background-color:rgba(0, 0, 0, 0.94);
  }
  
  > .message_box{
    width:356px; background-color:$N_COLOR_2;
    
    > .head{
    }
    
    > .body{
      text-align:center; font-size:18px; color:#cacaca; font-weight:600;
      padding-top:48px; padding-left:30px; padding-right:30px; padding-bottom:38px;
    }
    
    > .foot{
      text-align:center; padding-bottom:10px;
      
      > button{
        height:50px; padding-left:30px; padding-right:30px;
        font-size:18px; color:#acfc02; font-weight:800;
      }
    }
  }
}

.CustomAlert.on{
  left:0;
}

/* // CustomAlert [2022-07-06] */

/* About [2022-07-08] */
.atc_about{
  position:relative;
  margin-right:-$MAIN_SIDE_PADDING;
  padding-right:$MAIN_SIDE_PADDING;
  padding-bottom:70px;
  margin-bottom:50px;
  
  &:before{
    position:absolute; bottom:0; left:0; content:""; clear:both;
    width:100%; height:3px;
    background-color:$N_COLOR_8;
  }
  
  > .about_1{
    font-weight:600; font-size:26px; line-height:36px; letter-spacing:-0.5px;
  }
  
  > .about_2{
    margin-top:40px; font-weight:500; font-size:14px; line-height:24px; letter-spacing:-0.5px; color:$N_COLOR_7;
  }
}

.atc_office{
  position:relative;
  margin-right:-$MAIN_SIDE_PADDING;
  padding-bottom:70px;
  margin-bottom:50px;
  
  &:before{
    position:absolute; bottom:0; left:0; content:""; clear:both;
    width:100%; height:3px;
    background-color:$N_COLOR_8;
  }
}

.atc_location{
  
  margin-right:-$MAIN_SIDE_PADDING;
  
  > .address{
    font-weight:600; font-size:26px; line-height:36px; letter-spacing:-0.5px;
    
    > .tmb{
      display:block;
      margin-top:40px;
      font-size:0; line-height:0;
      overflow:hidden;
      
      > img{
        width:100%;
      }
    }
  }
}

/* // About [2022-07-08] */

/* swiper_ui [2022-07-03] */
.ksm_example{
  > .pic{
    > img{
      width:100%;
    }
  }
  
  > .title{
    font-size:18px;
    text-align:center;
  }
}

.swiper_ui{
  font-size:0;
  z-index:0;
  
  > .swiper{
    > .swiper-wrapper{
      
      > .swiper-slide{
      }
      
      > .swiper-slide.swiper-slide-active{
      }
      
    }
    
    > .swiper-pagination{
      position:relative; bottom:auto; background-color:#999999;
    }
    
    > .swiper-scrollbar{
      position:relative; bottom:0; height:10px; background-color:#282828;
      border-radius:0;
      
      > .swiper-scrollbar-drag{
        background-color:#acfc02;
        border-radius:0;
      }
    }
  }
  
}

.swiper_ui.SwiperCustom{
  
  > .swiper{
    > .swiper-wrapper{
      
      > .swiper-slide{
        > .shell{
          font-size:0;
        }
      }
      
      > .swiper-slide.swiper-slide-active{
      }
      
    }
    
    > .swiper-scrollbar{
      
      > .swiper-scrollbar-drag{
      }
    }
    
  }
  
  > .fraction_swiper{
    overflow:hidden; position:absolute; top:auto; left:-10000px; width:1px; height:1px; font-size:0; line-height:0;
    pointer-events:none;
  }
  
  > .pagination_area{
    margin-top:20px;
    padding-left:28px; padding-right:28px;
    
    display:flex; align-items:center;
    
    > .swiper-pagination{
      position:relative;
    }
    
    > .fraction_pagination{
      left:auto; bottom:auto;
      display:flex; justify-content:flex-start; align-items:flex-end;
      font-weight:400; font-size:14px; font-family: 'Poppins', sans-serif; color:$N_COLOR_5;
      margin-right:12px;
      max-width:66px;
      
      > span{
        display:inline-block;
        font-size:16px;
      }
      
      > span + span{
        padding-left:3px;
      }
      
      > .swiper-pagination-current{
        font-weight:700; font-size:20px; font-family: 'Poppins', sans-serif; color:white;
        width:25px;
      }
      
      > .swiper-pagination-total{
      }
    }
    
    > .progressbar_pagination{
      background-color:$N_COLOR_3;
      
      > .swiper-pagination-progressbar-fill{
        background-color:$COLOR_0;
      }
    }
  }
}

.swiper_ui.SwiperCreator{
  
  > .swiper{
    > .swiper-wrapper{
      
      > .swiper-slide{
        > .shell{
          font-size:0;
          
          > .pic{
            aspect-ratio:412 / 412;
            @include object_fit;
          }
        }
      }
      
    }
  }
}

.swiper_ui.SwiperCustom.SwiperAbout{
  .pagination_area{
    padding-left:0; padding-right:0;
  }
  
  > .swiper{
    > .swiper-wrapper{
      
      > .swiper-slide{
        aspect-ratio:384/257;
        @include object_fit;
      }
    }
  }
}

.swiper_ui.SwiperCustom.SwiperMainAbout{
  > .swiper{
    > .swiper-wrapper{
      
      > .swiper-slide{
        max-width:356px;
        aspect-ratio:356/238;
        @include object_fit;
      }
    }
  }
}

.swiper_ui.SwiperMainCreator{
  > .swiper{
    > .swiper-wrapper{
      transition: all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
      // easeInOutBack
      //transition: all 300ms cubic-bezier(0.68, -0.6, 0.32, 1.6);
      
      > .swiper-slide{
        width:200px;
        > .tmb{
          background-color:$N_COLOR_2;
          width:100%;
          height:200px;
          @include object_fit;
        }
      }
    }
  }
  
  > .swiper + .swiper{
    margin-top:30px;
  }
  
}

/* // swiper_ui [2022-07-03] */

/* SwiperMainVisual [2022-07-09(009)] */
.SwiperMainVisual{
  .swiper_item{
  }
  
  .swiper-slide-prev,
  .swiper-slide-active,
  .swiper-slide-duplicate-active{
    
    > .pic{
      width:100%; height:100vh;
      display:flex;
      
      > img.visual_img{
        width:100%; height:auto; min-height:$MIN_HEIGHT;
        object-fit:cover;
        animation-name:SwiperMainVisual_zoom;
        animation-duration:6s;
        animation-fill-mode:forwards;
      }
    }
  }
}

@keyframes SwiperMainVisual_zoom{
  0%{
    opacity:0;
    transform:scale(1);
  }
  20%{
    opacity:1;
    
  }
  100%{
    //transform:scale(1.5);
    transform:scale(1.1);
  }
}

/* // SwiperMainVisual [2022-07-09(009)] */

/* splash [2022-07-14] */
.splash_ui{
  animation:2.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.0s 1 normal forwards running splash;
  
  img{
    opacity:0;
    animation:0.1s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.7s 1 normal forwards running splash_img;
  }
}

.splash_ui:before{
  display:block; content:""; clear:both;
  position:absolute; z-index:1; left:0%; top:0; width:100%; height:100%;
  background:#ffffff url("@/assets/svg/logo_splash_black.svg") center center no-repeat;
  animation:1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.0s 1 normal forwards running splash_before;
}

@keyframes splash_before{
  0%{
    left:100%;
  }
  50%{
    left:0%;
  }
  100%{
    left:-100%;
  }
}

@keyframes splash{
  0%{
  }
  60%{
    opacity:1;
  }
  100%{
    opacity:0;
  }
}

@keyframes splash_img{
  0%{
  }
  100%{
    opacity:1;
  }
}

/* // splash [2022-07-14] */