/*
 * src/assets/scss/common.scss
 */

/* 구글 리캡챠 v3 */
.grecaptcha-badge{ display:none !important; }

html{
  height:100%;
}

body{
  height:100%;
  background-color:$BG_COLOR_0; color:$N_COLOR_8;
}

body.deactive_scroll{
  overflow:hidden;
}

#app{
  height:100%;
}

#page{
  position:relative;
  min-height:100%;
  max-width:$MAX_WIDTH;
  margin-left:auto; margin-right:auto;
  overflow:hidden;
  
}

#page.LayoutDefault{
  padding-top:$HEADER_HEI;
  padding-bottom:$FOOTER_HEI;
  
  header{
    transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
    position:fixed; z-index:7; left:0; top:0; width:100%;
    padding-top:22px; padding-bottom:6px;
    text-align:left;
    background-color:rgba(17, 17, 17, 1);
    
    > .inner{
      display:flex; flex-direction:row; flex-wrap:nowrap; justify-content:space-between;
      max-width:$MAX_WIDTH; margin-left:auto; margin-right:auto;
      
      > .logo{
        margin-left:28px;
        //height:$HEADER_HEI;
        display:flex; justify-content:space-between; align-items:center;
        
        > img{
          width:138px; height:22px;
        }
      }
      
      > .nav_trigger{
        //position:relative; width:48px; height:48px;
        //position:fixed; z-index:110; top:14px; right:16px;
        z-index:110;
        margin-right:16px;
        width:48px; height:48px;
        
        > span{
          position:absolute; left:50%;
          width:24px; height:4px;
          background-color:#ffffff;
          transform:translate(-50%, -50%);
          pointer-events:none;
        }
        
        > span:nth-of-type(1){
          top:calc(50% - 5px);
        }
        
        > span:nth-of-type(2){
          top:calc(50% + 5px);
        }
      }
      
      > .nav_trigger,
      > .nav_trigger > span{
        display:inline-block;
        transition:all .4s;
        box-sizing:border-box;
      }
      
      > .nav_trigger.active{
        > span:nth-of-type(1){
          -webkit-transform:translateX(-50%) translateY (5px) rotate (-45deg);
          transform:translateX(-50%) translateY(5px) rotate(-45deg);
        }
        
        > span:nth-of-type(2){
          -webkit-transform:translateX(-50%) translateY(-5px) rotate(45deg);
          transform:translateX(-50%) translateY(-5px) rotate(45deg);
        }
      }
    }
    
    > aside{
      opacity:0; right:-200%;
      transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
      z-index:100; position:fixed; top:0px;
      width:100%; height:100%; overflow:auto;
      background-color:$BG_COLOR_0;
      
      > .inner{
        max-width:$MAX_WIDTH;
        margin-left:auto; margin-right:auto;
        overflow:hidden;
        
        > nav{
          display:block;
          margin-left:28px; margin-right:62px;
          padding-top:77px; padding-bottom:147px;
          
          > a{
            display:block;
            text-shadow:-1px 0 $N_COLOR_8, 0 1px $N_COLOR_8, 1px 0 $N_COLOR_8, 0 -1px $N_COLOR_8;
            color:$N_COLOR_1; font-family:"Poppins", sans-serif; font-weight:800; letter-spacing:2px;
            font-size:44px;
            line-height:44px;
            opacity:0.1;
            transform:translate3d(200px, 0px, 0px);
          }
          
          > a + a{
            margin-top:50px;
          }
        }
        
        > .address{
          display:block; margin-left:28px; padding-top:51px; padding-bottom:77px;
          transform:translate3d($MAX_WIDTH, 0px, 0px);
          
          > .head{
            font-family: 'Poppins', sans-serif; font-weight:400; font-size:14px; line-height:24px; letter-spacing:-0.5px;
            color:$N_COLOR_7;
          }
          
          > .body{
            margin-top:8px;
            font-weight:600; font-size:18px; line-height:28px; letter-spacing:-0.5px;
          }
        }
        
        > .address:before{
          position:absolute; top:0; left:0; content:""; clear:both;
          width:100%; height:3px;
          background-color:$N_COLOR_8;
        }
      }
    }
    
    > aside.active{
      opacity:1; right:0;
      > .inner{
        > nav{
          > a{
            animation:0.4s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.1s 1 normal forwards running animation_aside;
            opacity:0;
          }
  
          > a:nth-child(1){
            animation-delay:0.1s;
          }
          > a:nth-child(2){
            animation-delay:0.2s;
          }
          > a:nth-child(3){
            animation-delay:0.3s;
          }
          > a:nth-child(4){
            animation-delay:0.4s;
          }
          > a:nth-child(5){
            animation-delay:0.5s;
          }
        }
  
        > .address{
          animation:0.4s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.6s 1 normal forwards running animation_aside1;
          opacity:0;
        }
      }
    }
  }
  
  > main{
    padding-top:$MAIN_TOP_PADDING;
    padding-left:$MAIN_SIDE_PADDING;
    padding-right:$MAIN_SIDE_PADDING;
    padding-bottom:$MAIN_BOTTOM_PADDING;
    
    /*
    > .titui_h1:first-of-type{
      transform:translate3d(0px, 50px, 0px);
      animation:0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0s 1 normal forwards running animation_titui_h1;
      opacity:0;
    }
    
    > .titui_h1:first-of-type ~ *{
      transform:translate3d(0px, 50px, 0px);
      animation:0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.5s 1 normal forwards running animation_subpage_contents;
      opacity:0;
    }
    */
  }
  
  > footer{
    font-family: 'Poppins', sans-serif; font-weight:400; font-size:14px;
    display:flex; align-items:center; justify-content:center;
    width:100%; height:$FOOTER_HEI;
    background-color:$N_COLOR_0; text-align:center;
    
    z-index:0; position:absolute; bottom:0px; left:0px;
  }
}

#page.hide_header{
  > header{
    top:-$HEADER_HEI;
  }
}

#page.fill_header{
  > header{
    background-color:rgba(17, 17, 17, 1);
  }
}

#page.LayoutDefault[data-name="메인"]{
  > main{
    padding-top:0;
  }
}

#page.LayoutDefault[data-name="회사소개"],
#page.LayoutDefault[data-name="크리에이터"],
#page.LayoutDefault[data-name="질문&답변"],
#page.LayoutDefault[data-name="뉴스"],
#page.LayoutDefault[data-name="채용"]
{
  > main{
    overflow:hidden;
    
    [data-trigger].titui_h1.visible{
      transform:translate3d(0px, 50px, 0px);
      animation:0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.0s 1 normal forwards running animation_subpage_contents;
      opacity:0;
      
      & + * {
        transform:translate3d(0px, 50px, 0px);
        animation:0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.5s 1 normal forwards running animation_subpage_contents;
        opacity:0;
      }
    }
  }
}

#page.LayoutEmpty{
  > main{
    padding:$MAIN_SIDE_PADDING;
  }
}

@keyframes animation_titui_h1{
  from{
    opacity:0;
    transform:translate3d(0px, 50px, 0px);
  }
  to{
    opacity:1;
    transform:translate3d(0px, 0px, 0px);
  }
}

@keyframes animation_subpage_contents{
  0%{
    opacity:0;
    transform:translate3d(0px, 10px, 0px);
  }
  
  100%{
    opacity:1;
    transform:translate3d(0px, 0px, 0px);
  }
}

@keyframes animation_aside{
  0%{
    opacity:0.1;
    transform:translate3d(200px, 0px, 0px);
  }
  100%{
    opacity:1;
    transform:translate3d(0px, 0px, 0px);
  }
}

@keyframes animation_aside1{
  0%{
    opacity:0.1;
    transform:translate3d($MAX_WIDTH, 0px, 0px);
  }
  100%{
    opacity:1;
    transform:translate3d(0px, 0px, 0px);
  }
}
