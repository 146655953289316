
        @import "@/assets/scss/_var.scss";
        @import "@/assets/scss/_mixin.scss";
        @import "@/assets/scss/_function.scss";
        
@charset "utf-8";
/**
 * Montserrat
 */
@font-face{
  font-family:"Montserrat";
  font-style:normal;
  font-weight:500;
  src:url(Montserrat-Medium.woff) format("woff");
}

@font-face{
  font-family:"Montserrat";
  font-style:normal;
  font-weight:600;
  src:url(Montserrat-SemiBold.woff) format("woff");
}

@font-face{
  font-family:"Montserrat";
  font-style:normal;
  font-weight:700;
  src:url(Montserrat-Bold.woff) format("woff");
}

@font-face{
  font-family:"Montserrat";
  font-style:normal;
  font-weight:800;
  src:url(Montserrat-ExtraBold.woff) format("woff");
}