/*
 * src/assets/scss/main.scss
 */

#page.LayoutDefault[data-name="메인"]{
  
  section + section{ margin-top:80px; }
  
  .visualsub_sec{ margin-top:30vw; }
  
  .atc_about{
    padding-bottom:0;
  }
  
  .atc_about:before{
    display:none;
  }
  
  & ~ .goto_career_btn{
    animation:5.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.9s 1 normal forwards running animation_visual_txt2;
  }
}

#page.LayoutDefault[data-name="메인"]:not(.fill_header){
  
  header{
    background-color:rgba(17, 17, 17, 0);
  }
}

@media screen and (min-width:#{$MAX_WIDTH}){
  #page.LayoutDefault[data-name="메인"]{
    .visualsub_sec{ margin-top:230.4px; }
  }
}

.visual_sec{
  @include full_width_section;
  margin-top:-$HEADER_HEI; height:100vh; min-height:$MIN_HEIGHT;
  overflow:hidden;
  
  > .atc_visual{
    position:absolute; z-index:1;
    top:0; left:0; width:100%; height:100vh; min-height:$MIN_HEIGHT;
    display:flex; flex-direction:column; justify-content:center;
    opacity:0;
    animation:2.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.3s 1 normal forwards running animation_visual_show;
    
    > .visual_0{
      //text-shadow:-1px 0 $N_COLOR_8, 0 1px $N_COLOR_8, 1px 0 $N_COLOR_8, 0 -1px $N_COLOR_8;
      color:$N_COLOR_1; font-family: "Poppins", sans-serif; font-weight:800; letter-spacing:2px;
      /*font-size:56px; line-height:68px;*/
      font-size:13.6vw; line-height:1.2em;
      /*padding-top:76px;*/ padding-left:$MAIN_SIDE_PADDING;
  
      -webkit-text-stroke: 1px $N_COLOR_8;
      color:rgba(0,0,0,0);
  
      .n_color_8{ text-shadow:none;}
      
      .plus{
        margin-left:1.2vw;
        /*font-size:66px;*/
        font-size:16vw;
      }
      
      > .flex_box{
        display:flex;
        align-items:center;
      }
      
      [data-word-order]{
        animation:0.1s cubic-bezier(0.215, 0.610, 0.355, 1.000) 2.1s 1 normal forwards running animation_visual_txt;
        opacity:0;
        display:inline-block;
      }
      [data-word-order="1"]{ animation-delay:2.1s; }
      [data-word-order="2"]{ animation-delay:2.2s; }
      [data-word-order="3"]{ animation-delay:2.3s; }
      [data-word-order="4"]{ animation-delay:2.4s; }
      [data-word-order="5"]{ animation-delay:2.5s; }
      [data-word-order="6"]{ animation-delay:2.6s; }
      [data-word-order="7"]{ animation-delay:2.7s; }
      [data-word-order="8"]{ animation-delay:2.8s; }
      [data-word-order="9"]{ animation-delay:2.9s; }
      [data-word-order="10"]{ animation-delay:3.0s; }
      [data-word-order="11"]{ animation-delay:3.1s; }
      [data-word-order="12"]{ animation-delay:3.2s; }
      [data-word-order="13"]{ animation-delay:3.3s; }
      [data-word-order="14"]{ animation-delay:3.4s; }
      [data-word-order="15"]{ animation-delay:3.5s; }
      [data-word-order="16"]{ animation-delay:3.6s; }
      [data-word-order="17"]{ animation-delay:3.7s; }
      [data-word-order="18"]{ animation-delay:3.8s; }
      [data-word-order="19"]{ animation-delay:4.0s;
        animation-name:animation_visual_txt_plus;
        animation-timing-function:linear;
        animation-duration:1s;
        transform:rotate(0deg);
      }
    }
    
    > .visual_1{
      
      display:flex; justify-content:flex-end;
      font-weight:500;
      /* 화면 너비 412px 기준으로 px값을 vw 근사치 값으로 바꿈 */
      /*font-size:16px; line-height:26px; */
      font-size:3.9vw; line-height:1.62em;
      /*margin-top:10.5vh;*/ margin-top:5.5vh; margin-bottom:5vh; padding-right:$MAIN_SIDE_PADDING;
      
      > .desc{
        animation:1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 5.1s 1 normal forwards running animation_visual_txt1;
        transform:translateX(120%);
  
        .color_0{
          /* font-size:18px; */
          font-size:1.1em;
        }
      }
    }
  }
}

@keyframes animation_visual_show{
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}

@keyframes animation_visual_txt{
  0%{
    transform:translateX(-5px);
  }
  5%{
    transform:translateX(0px);
  }
  100%{
    transform:translateX(0px);
    opacity:1;
  }
}

@keyframes animation_visual_txt_plus{
  0%{
    opacity:0.0;
    transform:rotate(0deg);
  }
  1%{
    opacity:1;
  }
  100%{
    opacity:1;
    transform:rotate(180deg)
  }
}

@keyframes animation_visual_txt1{
  0%{
    transform:translateX(120%);
  }
  100%{
    transform:translateX(0%);
  }
}

@keyframes animation_visual_txt2{
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}

@media screen and (min-width:#{$MAX_WIDTH}){
  .visual_sec{
    > .atc_visual{
      > .visual_0{
        font-size:104.5px; line-height:125.4px;
        
        .plus{
          margin-left:9.2px;
          font-size:122.9px;
        }
      }
      
      > .visual_1{
        font-size:30px; line-height:48.6px;
      }
    }
  }
}

@media screen and (orientation:landscape) and (max-width:#{$MAX_WIDTH}){
  .visual_sec > .atc_visual{
    > .visual_0{
      line-height:1em;
    }
  
    > .visual_1{
    }
  }
}

.visualsub_sec{
  
  .atc_visualsub{
    > .visual_2{
      padding-bottom:34px;
      
      > .word_0{
        
        > .eng{
          font-family: "Poppins", sans-serif; font-weight:800; text-align:center; letter-spacing:1px;
          /* font-size:51px; line-height:66px; */
          font-size:12.4vw; line-height:1.29em;
          
          > .plus{
            /*margin-left:5px;*/
            margin-left:1.2vw;
            /*font-size:71px;*/
            font-size:17vw;
          }
        }
        
        > .eng.flex_box{
          display:flex;
          justify-content:center; align-items:center;
        }
        
        > .kor{
          /*margin-top:17px;*/
          margin-top:2.1vh;
          line-height:26px; text-align:center;
          /* font-weight:600; font-size:18px; */
          font-size:4.5vw; line-height:1.44em;
          color:$N_COLOR_7;
        }
        
        > .flex_box{
          display:flex;
          justify-content:center; align-items:center;
        }
      }
      
      > .word_0 + .word_0{
        /*margin-top:50px;*/
        margin-top:6vh;
        
      }
      
      > .word_1{
        position:absolute; top:0; left:50%; width:calc(100% + #{$MAIN_SIDE_PADDING} * 2); overflow:hidden;
        transform:translate(-50%, -50%);
        font-family: "Poppins", sans-serif; font-weight:800; text-align:center; white-space:nowrap; text-indent:-7px;
        /*font-size:72px; line-height:88px;*/
        font-size:19.5vw; line-height:1.22em;
        color:rgba(255, 255, 255, 0.05);
      }
    }
  }
}

@media screen and (min-width:#{$MAX_WIDTH}){
  .visualsub_sec{
    
    .atc_visualsub{
      .visual_2{
        .word_0{
          > .eng{
            font-size:95.3px; line-height:123px;
            
            > .plus{
              margin-left:9.2px;
              font-size:130.6px;
            }
          }
          
          > .kor{
            font-size:34.6px; line-height:49.9px;
          }
        }
        
        .word_1{
          font-size:134.4px; line-height:164px;
        }
      }
    }
  }
}

.atc_creator{
  position:relative;
  
  > .more_btn{
    position:absolute; top:-67px; right:0;
  }
}

.atc_news{
  position:relative;
  
  > .more_btn{
    position:absolute; top:-67px; right:0;
  }
}

@media screen and (min-width:600px){
  
  .atc_news{
    
    .tmb_list_ui.news{
      > .item:nth-child(n+7){ display:none; }
    }
  }
}

@media screen and (max-width:599px){
  
  .atc_news{
    
    .tmb_list_ui.news{
      > .item:nth-child(n+5){ display:none; }
    }
  }
}

.atc_about{}

.atc_our_partner{
  
  > .desc{
    margin-top:10px; margin-bottom:30px;
    font-weight:500; font-size:16px; line-height:26px;
    color:$N_COLOR_7;
  }
}

.atc_ux_enm{
  @include full_width_section;
  margin-bottom:-70px;
  padding-left:$MAIN_SIDE_PADDING; padding-right:$MAIN_SIDE_PADDING;
  padding-bottom:70px;
  background-color:$N_COLOR_2;
  
  > .address{
    font-weight:500; font-size:18px; line-height:26px;
    color:$N_COLOR_6;
  }
  
  > .contact{
    margin-top:30px;
    
    > li{
      font-weight:500; font-size:18px; line-height:22px;
      color:$N_COLOR_6;
      
      > a[href^=mailto]{
        color:$N_COLOR_6;
      }
      
      .label{
        margin-right:26px; font-weight:600;
      }
    }
    
    > li + li{
      margin-top:8px;
    }
  }
  
  > .location{
    margin-top:68px;
    
    > .tmb{
      display:block;
      font-size:0; line-height:0;
      overflow:hidden;
      
      > img{
        width:100%;
      }
    }
  }
}

[data-trigger].visible.visual_2{
  
  .word_0:nth-child(1){
    animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.0s 1 normal forwards running animation_scroll_trigger;
    transform:translate3d(0px, 50px, 0px);
    opacity:0;
  }
  
  .word_0:nth-child(2){
    animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.5s 1 normal forwards running animation_scroll_trigger;
    transform:translate3d(0px, 50px, 0px);
    opacity:0;
  }
  
  .word_0:nth-child(3){
    animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.0s 1 normal forwards running animation_scroll_trigger;
    transform:translate3d(0px, 50px, 0px);
    opacity:0;
  }
  
}

.partner{
  .item[data-trigger].invisible{
    opacity:0;
  }
  .item[data-trigger]:nth-child(odd).visible{
    animation:0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.2s 1 normal forwards running animation_partner;
    opacity:0;
    
  }
  .item[data-trigger]:nth-child(even).visible{
    animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.4s 1 normal forwards running animation_partner;
    opacity:0;
  }
}

@keyframes animation_partner{
  0%{
    opacity:0;
    transform:scale3d(.3, .3, .3);
  }
  100%{
    opacity:1;
    ransform:scale3d(1, 1, 1);
  }
}

[data-trigger].visible.sec_ux_enm{
  .titui_h1{
    img{
      animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.0s 1 normal forwards running animation_scroll_trigger;
      transform:translate3d(0px, 50px, 0px);
      opacity:0;
    }
  }
  
  .atc_ux_enm{
    > .address{
      animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.5s 1 normal forwards running animation_scroll_trigger;
      transform:translate3d(0px, 50px, 0px);
      opacity:0;
    }
    
    > .contact{
      > li:nth-child(1){
        animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.0s 1 normal forwards running animation_scroll_trigger;
        transform:translate3d(0px, 50px, 0px);
        opacity:0;
      }
      > li:nth-child(2){
        animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.2s 1 normal forwards running animation_scroll_trigger;
        transform:translate3d(0px, 50px, 0px);
        opacity:0;
      }
      > li:nth-child(3){
        animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.4s 1 normal forwards running animation_scroll_trigger;
        transform:translate3d(0px, 50px, 0px);
        opacity:0;
      }
    }
  }
}

.creator_swiper_wrap{
  @include full_width_section;
}

.about_swiper_wrap{
  @include full_width_section;
}

@keyframes animation_scroll_trigger{
  from{
    opacity:0;
    transform:translate3d(0px, 50px, 0px);
  }
  to{
    opacity:1;
    transform:translate3d(0px, 0px, 0px);
  }
}


