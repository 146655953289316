
        @import "@/assets/scss/_var.scss";
        @import "@/assets/scss/_mixin.scss";
        @import "@/assets/scss/_function.scss";
        


$MAX_CAREER_WIDTH:746px;
$CAREER_SIDE_PADDING:22px;

.goto_career_btn{
  position:fixed; z-index:100; bottom:40px; right:$CAREER_SIDE_PADDING;
  transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
  opacity:1;
  
  > div{
    display:flex; justify-content:center; align-items:center;
    width:90px; height:90px; border-radius:45px;
    background-color:$COLOR_0; color:$N_COLOR_0;
    font-weight:800; font-size:16px; line-height:19px; text-align:center; letter-spacing:-0.5px;
  }
}

.goto_career_btn.hide{
  opacity:0;
}

@media screen and (min-width:#{$MAX_CAREER_WIDTH}){
  .goto_career_btn{
    right:calc(50% - #{$MAX_CAREER_WIDTH} / 2 + #{$CAREER_SIDE_PADDING} / 2);
  }
}

