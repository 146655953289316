
        @import "@/assets/scss/_var.scss";
        @import "@/assets/scss/_mixin.scss";
        @import "@/assets/scss/_function.scss";
        
@charset "utf-8";
/**
 * Pretendard
 */
@font-face{
  font-family:"Pretendard";
  font-style:normal;
  font-weight:100;
  src:url(Pretendard-Thin.subset.woff) format("woff");
}

@font-face{
  font-family:"Pretendard";
  font-style:normal;
  font-weight:200;
  src:url(Pretendard-ExtraLight.subset.woff) format("woff");
}

@font-face{
  font-family:"Pretendard";
  font-style:normal;
  font-weight:300;
  src:url(Pretendard-Light.subset.woff) format("woff");
}

@font-face{
  font-family:"Pretendard";
  font-style:normal;
  font-weight:400;
  src:url(Pretendard-Regular.subset.woff) format("woff");
}

@font-face{
  font-family:"Pretendard";
  font-style:normal;
  font-weight:500;
  src:url(Pretendard-Medium.subset.woff) format("woff");
}

@font-face{
  font-family:"Pretendard";
  font-style:normal;
  font-weight:600;
  src:url(Pretendard-SemiBold.subset.woff) format("woff");
}

@font-face{
  font-family:"Pretendard";
  font-style:normal;
  font-weight:700;
  src:url(Pretendard-Bold.subset.woff) format("woff");
}

@font-face{
  font-family:"Pretendard";
  font-style:normal;
  font-weight:800;
  src:url(Pretendard-ExtraBold.subset.woff) format("woff");
}

@font-face{
  font-family:"Pretendard";
  font-style:normal;
  font-weight:900;
  src:url(Pretendard-Black.subset.woff) format("woff");
}



