/*
 * src/assets/scss/layer_popup.scss
 */
/* FullLayer_0 [2022-07-09(009)] */
#FullLayer_0{
  .body{
    padding-left:$MAIN_SIDE_PADDING; padding-right:$MAIN_SIDE_PADDING;
    
    > .SwiperCustom{
      @include full_width_section;
    }
  }
  
  .info_section{
    margin-top:30px;
    
    > .area0{
      > .text0{
        font-weight:600; font-size:26px; line-height:36px;
      }
    }
    
    > .area1{
      margin-top:14px;
      font-size:18px; color:#cacaca;
    }
    
  }
}

/* // FullLayer_0 [2022-07-09(009)] */

/* FullLayer_1 [2022-07-11(011)] */
#FullLayer_1{
  > .popup{
    > .body{
      
      padding-top:40px;
      padding-left:$MAIN_SIDE_PADDING; padding-right:$MAIN_SIDE_PADDING;
      font-size:14px; font-weight:500; line-height:24px; color:#CACACA;
      
      > .main_title{
        font-weight:600; font-size:26px; margin-bottom:40px;
        color:white;
      }
      
      > section{
        + section{
          margin-top:24px;
        }
      }
      
      .tit_0{
        color:white;
      }
    }
  }
}

/* // FullLayer_1 [2022-07-11(011)] */
