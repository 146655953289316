/*
 * src/assets/scss/form.scss
 */
@import "@/components/form/style.scss";

.cst_btn{
  display:inline-flex; align-items:center; justify-content:center;
  font-size:18px; font-weight:800;
  height:56px; padding:0 14px;
  color:$N_COLOR_0; background-color:#dddddd;
}

.cst_btn[theme="A"]{
  color:$N_COLOR_0; background-color:$COLOR_0;
}

.cst_btn.disabled{
  color:$N_COLOR_4;
  background-color:$N_COLOR_3;
}

/**/
.cst_chk{
  display:inline-flex; align-items:center;
  font-size:0;
  
  > input[type=checkbox]{
    outline:none;
    transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
    background-image:url(@/assets/svg_icon/chk_0.svg);
    width:21px; height:21px; appearance:none;
    margin-right:9px;
  }
  
  > input[type=checkbox]:checked{
    background-image:url(@/assets/svg_icon/chk_0_on.svg);
  }
  
  > input[type=checkbox] + span.label{
    font-size:18px;
  }
  
  > input[type=text]{
    z-index:0; position:absolute; top:130px; left:0px;
  }
}

/* CustomFormChild [2022-07-08] */
$CustomFormChild_hei:30px;
.CustomFormChild{
  font-size:0; z-index:1;
  
  & + &{
    margin-top:22px;
  }
  
  &.has_title{
    box-shadow:0px 2px 0px $N_COLOR_4;
    
    &:after{
      transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
      display:inline-block; content:"";
      position:absolute; z-index:3;
      background-position:center; background-repeat:no-repeat;
      width:0; height:3px; bottom:-3px; left:0px;
    }
    
    &.is_focus:after,
    &.is_error:after{
      width:100%;
    }
    
    &.is_focus:after{
      background-color:$N_COLOR_8;
    }
    
    &.is_error:after{
      background-color:$WARN_COLOR_0;
    }
    
    & > article{
      position:relative;
      
      > label{
        font-size:14px; font-weight:500; line-height:24px;
        color:$N_COLOR_6;
      }
      
      > span{
        font-size:14px; font-weight:500; line-height:24px;
        color:$WARN_COLOR_0;
      }
    }
    
  }
  
}

/* // CustomFormChild [2022-07-08] */

/* CustomInput [2022-07-08] */
.CustomInput{
  
  > article{
    
    > input{
      display:block; width:100%; box-sizing:border-box;
      background:none; border:none; outline:none;
      height:$CustomFormChild_hei;
      font-size:18px; font-weight:600; color:$N_COLOR_8;
    }
    
  }
  
}

/* // CustomInput [2022-07-08] */

/* CustomCheckbox [2022-07-08(008)] */
.CustomCheckbox{
  display:inline-flex; align-items:center;
  font-size:0;
  box-shadow:none;
  
  &.is_error{
    > article{
      > input[type=checkbox] + .link{
        color:$WARN_COLOR_0;
      }
    }
  }
  
  > article{
    > input[type=checkbox]{
      outline:none;
      transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
      background-image:url(@/assets/svg_icon/chk_0.svg);
      width:21px; height:21px; appearance:none;
      margin-right:9px;
      
      &:checked{
        background-image:url(@/assets/svg_icon/chk_0_on.svg);
        
        + .link{
          color:$N_COLOR_8;
        }
      }
    }
    
    > .link{
      font-size:18px; color:$N_COLOR_6; text-decoration:underline;
      
      &[href]{
        text-decoration:underline;
      }
    }
  }
}

/* // CustomCheckbox [2022-07-08(008)] */

/* CustomFileInput [2022-07-08(008)] */
.CustomFileInput{
  > article{
    > .custom_file_input{
      
      > .label{
        display:flex; align-items:center; justify-content:left;
        width:calc(100% - 46px); height:$form_el_height;
        font-size:18px; line-height:28px; font-weight:600; color:$N_COLOR_6;
        white-space:nowrap;
        cursor:pointer;
        
        > .placeholder{
        
        }
        
        > .filename{
          color:$N_COLOR_6;
          width:100%; text-overflow:ellipsis; white-space:nowrap; overflow:hidden;
        }
      }
      
    }
    
    > input[type=file]{
      height:$CustomFormChild_hei;
      z-index:0; position:absolute; top:0px; left:0px;
      width:100%; height:100%;
      opacity:0;
      //opacity:0.2; background-color:yellow;
    }
    
    > .reset_btn{
      width:$CustomFormChild_hei; height:$CustomFormChild_hei;
      position:absolute; z-index:1; cursor:pointer;
      top:50%; transform:translateY(-50%); right:8px;
      pointer-events:none;
      
      &:before{
        transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
        display:inline-block; content:"";
        position:absolute; z-index:3;
        background-image:url(@/assets/svg_icon/icon_file_input.svg); background-size:14px; background-position:center; background-repeat:no-repeat;
        width:100%; height:100%;
      }
      
      &.active{
        pointer-events:auto;
      }
      
      &.active:before{
        transform:rotate(45deg);
      }
      
    }
    
  }
}

/* // CustomFileInput [2022-07-08(008)] */

/* CustomTextarea [2022-07-09(009)] */
.CustomTextarea{
  
  > article{
    .ta_wrap{
      display:flex; align-items:flex-end;
      
      > textarea{
        display:block;
        width:100%; height:$CustomFormChild_hei; resize:none; padding:0;
        font-size:18px; font-weight:600; color:white; line-height:$CustomFormChild_hei;
        background:none; outline:none; border:none;
      }
      
      > .len_box{
        min-width:70px; text-align:right;
        color:$N_COLOR_6; font-family: 'Poppins', sans-serif; font-style:normal;font-weight:400;font-size:14px;line-height:24px;
        text-align:right; white-space:nowrap;
      }
    }
    
  }
}

/* // CustomTextarea [2022-07-09(009)] */

/* CustomSeletbox [2022-07-09(009)] */
.CustomSeletbox{
  
  &.is_focus{
    z-index:10;
  }
  
  > article{
    > .custom_select{
      position:relative; z-index:10; user-select:none;
      display:block;
      width:100%; box-sizing:border-box;
      height:$form_el_height;
      font-family:"Pretendard"; font-size:18px; font-weight:600; color:white; letter-spacing:-0.5px;
      transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
      
      > .label{
        display:flex; align-items:center; justify-content:left;
        width:100%; height:$form_el_height;
        cursor:pointer;
        
        &:before{
          transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
          display:inline-block; content:"";
          position:absolute; z-index:3;
          background-image:url(@/assets/svg_icon/qna_down_arrow.svg); background-position:center; background-repeat:no-repeat;
          width:16px; height:11px; top:50%; transform:translateY(-50%) rotate(0deg); right:15px;
        }
        
      }
      
      > .options{
        transition:all 0ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
        width:100%; overflow:hidden;
        background-color:$N_COLOR_2;
        
        z-index:10; position:absolute; bottom:-3px; transform:translateY(100%); left:0px;
        
        padding-top:0; padding-bottom:0;
        max-height:0;
        
        &.is_focus{
          //padding-top:30px; padding-bottom:30px;
          padding-top:20px; padding-bottom:20px;
          max-height:100vh;
        }
        
        > li{
          font-size:18px; color:white; letter-spacing:-0.5px; line-height:28px;
          cursor:pointer;
          padding-left:20px; padding-right:20px; padding-top:10px; padding-bottom:10px;
        }
        
        > li + li{
          //margin-top:20px;
        }
      }
      
    }
    
    > select{
      z-index:0; position:absolute; top:0px; left:0px; width:100%; height:100%;
      pointer-events:none; background-color:$N_COLOR_2; color:white; outline:none;
      opacity:0;
      //opacity:0.5;
      
      > option{
        border:none;
        outline:none;
        cursor:pointer;
        padding:20px;
        background-color:$N_COLOR_2; color:white;
        font-size:18px;
        //display:none;
      }
    }
  }
}

/* // CustomSeletbox [2022-07-09(009)] */
