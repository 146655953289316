
        @import "@/assets/scss/_var.scss";
        @import "@/assets/scss/_mixin.scss";
        @import "@/assets/scss/_function.scss";
        

.layer_popup.on{
  > .popup{
    opacity:0;
    animation:0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.0s 1 normal forwards running fadein_scale;
  }
}

@keyframes fadein_scale{
  from{
    opacity:0;
    transform:scale(0.7);
  }
  to{
    opacity:1;
    transform:scale(1);
  }
}

.support_completed{
  background-color:$N_COLOR_2; text-align:center;
  
  > article.atc_0{
    padding-top:112px;
    font-weight:600; font-size:26px; line-height:36px; letter-spacing:-0.5px;
    background:url("@/assets/svg_icon/complete.svg") no-repeat center 48px; background-size:48px 30px;
  }
  
  > article.atc_1{
    padding-left:31px; padding-right:31px; padding-top:14px; padding-bottom:40px;
    font-weight:500; font-size:14px; line-height:24px; letter-spacing:-0.5px;
    color:$N_COLOR_7;
  }
  
  > article.atc_2{
  
  }
}
